$(document).ready(function () {


    $(".promo-slider").slick({
        prevArrow: '<div class="promo-slider__arrow promo-slider__arrow-prev"></div>',
        nextArrow: '<div class="promo-slider__arrow promo-slider__arrow-next"></div>',
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $(".events-slider").slick({
        prevArrow: '<div class="events-slider__arrow events-slider__arrow-prev"></div>',
        nextArrow: '<div class="events-slider__arrow events-slider__arrow-next"></div>',
        slidesToShow: 4,
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $(".founders-slider").slick({
        prevArrow: '<div class="founders-slider__arrow founders-slider__arrow-prev"></div>',
        nextArrow: '<div class="founders-slider__arrow founders-slider__arrow-next"></div>',
        slidesToShow: 5,
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    if($(".contacts__map").length) {
        ymaps.ready(init);
    }
    function init(){
        var myMap = new ymaps.Map("contacts__map", {
            center: [55.809054, 37.572351],
            zoom: 16
        });

        var myPlacemark = new ymaps.Placemark([55.809054, 37.572351], {});
        myMap.geoObjects.add(myPlacemark);
    }

    $(".questions-item").click(function () {
        $this = $(this);
        $answer = $this.find(".questions-item__body");

        if($this.hasClass("questions-item_active")) {
            $this.removeClass("questions-item_active");
            $answer.slideUp();
        } else {
            $this.addClass("questions-item_active");
            $answer.slideDown();
        }
    });

    /* NEW */
    $("[type='file']").change(function(){
        var fileResult = $(this).val();
        $(this).parent().find('.form-fileload__file-load').find('input').val(fileResult);
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj, pObj, cpName;
            $.each(pair.name.split("."), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    $("form").submit(function () {
        return false;
    });

    function submitForm(form) {
        var $form = $(form);
        var formData = new FormData($form.get(0));
        formData.append("json", JSON.stringify(getFormObject($(form).serializeArray())));

        console.log(getFormObject($(form).serializeArray()));

        axios({
            method: "post",
            url: "http://html.pavlyutkin.ru/sender-2.php",
            data: formData,
            config: { headers: {"Content-Type": "multipart/form-data" }},
            onUploadProgress: function(progressEvent) {}
        }).then(function (response) {
            console.log(response);
            $.fancybox.open('<div class="message"><h2>Спасибо!</h2><p>Ваша заявка отправлена!</p></div>');
        }).catch(function (response) {});
    }

    $(".summary-form-mentor").each(function () {
        $(this).validate({
            rules: {
                "name": {
                    required: true,
                },
                "birthdate": {
                    required: true,
                },
                "company": {
                    required: true,
                },
                "education": {
                    required: true,
                },
                "email": {
                    required: true,
                },
                "english-level": {
                    required: true,
                },
                "experience": {
                    required: true,
                },
                "international-experience": {
                    required: true,
                },
                "international-experience-message": {
                    required: true,
                },
                "learned": {
                    required: true,
                },
                "middlename": {
                    required: true,
                },
                "name": {
                    required: true,
                },
                "participated": {
                    required: true,
                },
                "phone": {
                    required: true,
                },
                "position": {
                    required: true,
                },
                "sex": {
                    required: true,
                },
                "surname": {
                    required: true,
                },
            },
            submitHandler: function (form) {
                submitForm(form);
            }
        });
    });

    $(".summary-form-ward").each(function () {
        $(this).validate({
            rules: {
                "name": {
                    required: true,
                },
                "birthdate": {
                    required: true,
                },
                "company": {
                    required: true,
                },
                "education": {
                    required: true,
                },
                "email": {
                    required: true,
                },
                "english-level": {
                    required: true,
                },
                "experience": {
                    required: true,
                },
                "international-experience": {
                    required: true,
                },
                "international-experience-message": {
                    required: true,
                },
                "learned": {
                    required: true,
                },
                "middlename": {
                    required: true,
                },
                "name": {
                    required: true,
                },
                "participated": {
                    required: true,
                },
                "phone": {
                    required: true,
                },
                "position": {
                    required: true,
                },
                "sex": {
                    required: true,
                },
                "surname": {
                    required: true,
                },
                "letter": {
                    required: true,
                },
                "problem-work": {
                    required: true,
                },
                "problem-work-message": {
                    required: true,
                },
                "scientific-work": {
                    required: true,
                },
                "scientific-work-message": {
                    required: true,
                },
            },
            submitHandler: function (form) {
                submitForm(form);
            }
        });
    });
});
